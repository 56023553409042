.cocktail_body {
    position: relative;
    height: max-content;
    font-family: Literata, 'Times New Roman', Times, serif;
    font-weight: lighter;
    background-color: #0f0f0f;
    color: black;
}

.cocktail_form {
    display: block;
    padding: 12px;
    line-height: 30px;
    background-color: magenta;

    label {
        display: block;
        line-height: 30px;
    }
}

.image {
    width: 75%;
    height: auto;
    left: 100px;
    top: 100px;
}

.cocktail_drink {
    position: absolute;
    top: 34%;
    left: 8%;
    width: 50%;
    font-size: 5vw;
}

.ingredient {
    position: absolute;
    width: 50%;
    font-size: 3vw;
}

.ing1 {
    top: 65.5%;
    left: 30.5%;
    text-align: left;
}

.ing2 {
    top: 70.5%;
    left: 29%;
    text-align: left;
    font-size: 2.5vw;
}